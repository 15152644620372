import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import Layout from '../components/layout';
import { OpsEventContext } from '../ops-event.context';
import styles from './index.module.css';
import { Link, navigate } from 'gatsby';

function Invitation({ location }) {
  const {
    opsEvent,
    eventType,
    consents,
    updateConsents,
    initialConsents,
  } = useContext(OpsEventContext);
  const params = new URLSearchParams(location.search);

  const candidateQueryParams = params.get('candidate-id')
    ? `&candidate-id=${params.get('candidate-id')}`
    : '';
  const tokenQueryParams = params.get('token')
    ? `&token=${params.get('token')}`
    : '';

  useEffect(() => {
    if (
      !params.get('event-id') ||
      !params.get('candidate-id') ||
      !params.get('token')
    ) {
      navigate('/404');
    }
  }, []);

  function check(path, value) {
    updateConsents(opsEvent, eventType, initialConsents, {
      ...consents,
      [path]: value,
    });
  }

  return (
    <Layout location={location}>
      {opsEvent && eventType && initialConsents && (
        <>
          <h1 className={styles.title}>Invitation au {eventType.name}</h1>
          <p>
            Suite à notre entretien téléphonique, j’ai le plaisir de vous
            inviter au {eventType.name} qui se déroulera le&nbsp;
            {opsEvent.dateCreated}
            &nbsp;à&nbsp;
            {opsEvent.location.city}. Merci de répondre en nous confirmant ou
            non votre participation à l&apos;événement.
          </p>
          <p>
            Dans l&apos;attente de vous rencontrer au&nbsp;{eventType.name}, je
            vous souhaite une très bonne journée.
          </p>

          <h2 className={styles.confirmInvit}>
            Merci de confirmer ou non votre présence au&nbsp;{eventType.name}
            &nbsp;:
          </h2>

          <div>
            <Link
              className={styles.btnGhost}
              to={`/cancel?event-id=${opsEvent.id}${candidateQueryParams}${tokenQueryParams}`}
            >
              Annuler
            </Link>
            <Link
              className={styles.btn}
              to={`/confirm?event-id=${opsEvent.id}${candidateQueryParams}${tokenQueryParams}`}
            >
              Confirmer
            </Link>
          </div>

          {!initialConsents.rightsConsent && (
            <div className={styles.checkboxContainer}>
              <input
                className={styles.checkbox}
                type="checkbox"
                id="rights-consent"
                name="rights-consent"
                defaultChecked={!!consents.rightsConsent}
                onChange={() => check('rightsConsent', !consents.rightsConsent)}
              />
              <label htmlFor="rights-consent">
                J&apos;accepte que mes données soient transférées à la caisse
                régionale du Crédit Agricole co-organisatrice du e-job dating à
                des fins de sollicitations commerciales.
              </label>
            </div>
          )}
        </>
      )}
    </Layout>
  );
}

Invitation.propTypes = {
  location: PropTypes.object,
};

export default Invitation;
